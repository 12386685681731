import { ResourceBookingModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class ResourceBookingService {
  constructor() {}

  getCourtActivitiesByCenter(searchedCenterId: number): Promise<[]> {
    return backendApi.post('resource-booking/court-activities-by-center', { searchedCenterId });
  }

  getAvailableStartTimesAndResources(
    centerId: number,
    activityId: number,
    date: string,
    durations: number[]
  ): Promise<ResourceBookingModel[]> {
    return backendApi.post('resource-booking/available-start-times-and-resources', { centerId, activityId, date, durations });
  }

  bookResource(
    personId: number,
    personCenterId: number,
    activityId: number,
    centerId: number,
    key: number,
    startTime: string | undefined,
    date: string,
    duration: number
  ): Promise<boolean> {
    return backendApi.post('resource-booking/book-resource', {
      personId, // FIXME: should be selectedUserId to be able to guard against unauthorized users
      personCenterId, // FIXME: same
      activityId,
      centerId,
      key,
      startTime,
      date,
      duration,
    });
  }

  getAvailableClipcards(
    selectedUserId: number,
    selectedUserCenterId: number,
    activityId: number,
    activityCenterId: number,
    date: string,
    startTime: string,
    duration: number
  ): Promise<[]> {
    return backendApi.post('resource-booking/available-clipcards', {
      selectedUserId,
      selectedUserCenterId,
      activityId,
      activityCenterId,
      date,
      startTime: startTime,
      duration: duration,
    });
  }
}
