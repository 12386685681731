import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { UserProfileEnum } from '../../../common/src';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'Login', sysprop: 'loginPage' },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { title: 'login.forgot' },
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/forgot-password/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:memberHomeClubId/:memberId/:securityCode',
    name: 'ResetPassword',
    props: true,
    meta: { title: 'login.resetPassword' },
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/reset-password/ResetPassword.vue'),
  },
  /***
   * Menu Links
   */
  /** DASHBOARD : Activities and Schedule */
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      banner: 'dashboardBanner',
      title: 'header.dashboard',
      navbarName: 'header.dashboard',
      cy: 'home-link',
      sysprop: 'dashboardPage',
      access: ['ANY'], // It's a fallback, it should be available for any logged in user
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue'),
  },
  /** Courses  */
  {
    path: '/courses',
    name: 'Courses',
    meta: {
      banner: 'courseBanner',
      title: 'header.courses',
      navbarName: 'header.courses',
      cy: 'courses-link',
      sysprop: 'coursesPage',
    },
    component: () => import(/* webpackChunkName: "courses" */ '../views/courses/Courses.vue'),
  },
  /** Courses-Payment  */
  {
    path: '/courses-payment',
    name: 'CoursesPayment',
    meta: {
      title: 'header.courses',
      access: [UserProfileEnum.MemberLevel],
    },
    component: () => import(/* webpackChunkName: "courses-payment" */ '../views/courses-payment/CoursesPayment.vue'),
  },
  /** Camps  */
  {
    path: '/camps',
    name: 'Camps',
    meta: {
      banner: 'campsBanner',
      title: 'header.camps',
      navbarName: 'header.camps',
      cy: 'camps-link',
      sysprop: 'campsPage',
    },
    component: () => import(/* webpackChunkName: "camps" */ '../views/camps/Camps.vue'),
  },
  /** Camps-Payment  */
  {
    path: '/camps-payment',
    name: 'CampsPayment',
    meta: {
      title: 'header.camps',
      access: [UserProfileEnum.MemberLevel],
    },
    component: () => import(/* webpackChunkName: "camps-payment" */ '../views/camps-payment/CampsPayment.vue'),
  },
  /** invoices - payments */
  {
    path: '/payments',
    name: 'Payments',
    meta: {
      banner: 'paymentsBanner',
      title: 'header.payments',
      navbarName: 'header.payments',
      cy: 'payments-link',
      access: [UserProfileEnum.MemberLevel],
      sysprop: 'paymentPage',
    },
    component: () => import(/* webpackChunkName: "Payments" */ '../views/invoices/Invoices.vue'),
  },
  /** Bookings : Bookings and Schedule */
  {
    path: '/booking/:selectedMemberKey?',
    name: 'Booking',
    meta: {
      banner: 'bookingBanner',
      title: 'header.booking',
      navbarName: 'header.booking',
      cy: 'booking-link',
      access: [UserProfileEnum.MemberLevel],
      allowAnonymousSysprop: 'classBookingFlowAnonymous',
      sysprop: 'classBookingFlow',
    },
    props: true,
    component: () => import(/* webpackChunkName: "booking" */ '../views/booking/Booking.vue'),
  },
  /** Resources booking  */
  {
    path: '/resource-booking',
    name: 'ResourceBooking',
    meta: {
      banner: 'resourceBookingBanner',
      title: 'header.resourcebooking',
      cy: 'resources-booking-link',
      navbarName: 'header.resourcebooking',
      sysprop: 'resourceBookingPage',
    },
    component: () => import(/* webpackChunkName: "resource-booking" */ '../views/resource-booking/ResourceBooking.vue'),
  },
  /** clipcards */
  {
    path: '/clipcards',
    name: 'Clipcards',
    meta: {
      banner: 'clipcardBanner',
      title: 'header.clipcards',
      navbarName: 'header.clipcards',
      cy: 'clipcard-link',
      access: [UserProfileEnum.MemberLevel],
      sysprop: 'clipcardFlow',
    },
    component: () => import(/* webpackChunkName: "clipcards" */ '../views/clipcards/Clipcards.vue'),
  },
  /***
   * Profile
   */
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      banner: 'profileBanner',
      title: 'header.profile',
      access: [UserProfileEnum.MemberLevel],
      sysprop: 'profilePage',
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Profile.vue'),
  },
  /***
   * Adyen Specific
   */
  {
    path: '/adyen-payment',
    name: 'Adyen Payment',
    meta: {
      title: 'adyen.profile',
    },
    component: () => import(/* webpackChunkName: "adyen-drop-in" */ '../views/payment-providers/adyen-drop-in/AdyenDropIn.vue'),
  },
  /**
   *  payment result
   * */
  {
    path: '/payment-result/:provider',
    name: 'PaymentResult',
    meta: { title: 'payment.paymentResult' },
    props: true,
    component: () => import(/* webpackChunkName: "paymentResult" */ '../views/payment-providers/PaymentResult.vue'),
  },
  {
    path: '/questionnaire/after-join',
    name: 'QuestionnaireAfterJoin',
    meta: { title: 'header.afterJoinQuestionnaire', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "paymentResult" */ '../views/join/questionnaire/AfterJoinQuestionnaire.vue'),
  },
  /** ERRORS */
  {
    path: '/token-error',
    name: 'SSOTokenError',
    meta: { title: 'header.ssoTokenError' },
    component: () => import(/* webpackChunkName: "sso-token-error" */ '../views/errors/SSOTokenError.vue'),
  },
  {
    path: '/unauthorized',
    name: 'UnauthorizedError',
    meta: { title: 'Unauthorized' },
    component: () => import(/* webpackChunkName: "sso-token-error" */ '../views/errors/Unauthorized.vue'),
  },
  {
    path: '/404',
    name: 'Error404',
    meta: { title: 'header.notFound' },
    component: () => import(/* webpackChunkName: "error404" */ '../views/errors/404.vue'),
  },
  /** CLIPCARDS */
  {
    path: '/clipcard/:clipcardId/:clipcardCenterId/:clipcardSize?',
    name: 'ClipcardDetails',
    meta: { title: 'header.clipcardDetails', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "clipcardDetails" */ '../views/clipcards/ClipcardDetails.vue'),
  },
  /** RESOURCE BOOKING CLIPCARD */
  {
    path: '/resource-booking/clipcard/:clipcardId/:clipcardCenterId/:selectedMemberId/:selectedMemberCenterId/:resourceKey/:activityId/:activityCenterId/:date/:startTime/:duration/:clipcardSize?',
    name: 'ResourceBookingClipcardDetails',
    meta: { title: 'header.resourceBookingClipcardDetails', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "resourceBookingClipcardDetails" */ '../views/clipcards/ClipcardDetails.vue'),
  },
  /** RESOURCE BOOKING CLIPCARDS*/
  {
    path: '/resource-booking/clipcards/:selectedMemberId/:selectedMemberCenterId/:resourceKey/:activityId/:activityCenterId/:date/:startTime/:duration',
    name: 'ResourceBookingClipcards',
    meta: { title: 'header.resourceBookingClipcards', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "resourceBookingClipcards" */ '../views/clipcards/Clipcards.vue'),
  },
  /** CLASS BOOKING CLIPCARD */
  {
    path: '/booking/clipcard/:clipcardId/:clipcardCenterId/:selectedMemberId/:selectedMemberCenterId/:bookingId/:bookingCenterId/:clipcardSize?',
    name: 'ClassBookingClipcardDetails',
    meta: { title: 'header.classBookingClipcardDetails', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "classBookingClipcardDetails" */ '../views/clipcards/ClipcardDetails.vue'),
  },
  /** CLASS BOOKING CLIPCARDS*/
  {
    path: '/booking/clipcards/:selectedMemberId/:selectedMemberCenterId/:bookingId/:bookingCenterId',
    name: 'ClassBookingClipcards',
    meta: { title: 'header.classBookingClipcards', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "classBookingClipcards" */ '../views/clipcards/Clipcards.vue'),
  },
  /** SSO */
  {
    path: '/sso',
    name: 'SSO',
    meta: { title: 'header.SSO' },
    component: () => import(/* webpackChunkName: "sso" */ '../views/sso/sso.vue'),
  },
  /** STAFF */
  {
    path: '/staff/:selectedMemberId/:selectedMemberCenterId',
    name: 'Staff',
    meta: { title: 'header.staff', access: [UserProfileEnum.StaffLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "staff" */ '../views/staff/staff.vue'),
    children: [
      {
        path: 'summary',
        name: 'Staff-MemberSummary',
        meta: { title: 'header.summary', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "staff-member-summary" */ '../views/member-summary/MemberSummary.vue'),
      },
      {
        path: 'clipcard-subscriptions',
        name: 'Staff-ClipcardSubscriptions',
        meta: { title: 'header.clipcardSubscriptions', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "staff-clipcard-subscriptions" */ '../views/clipcards/ClipcardSubscriptions.vue'),
      },
      {
        path: 'clipcard-subscriptions-summary/:shoppingBasketId/:clipcardSubscriptionId/:clipcardSubscriptionCenterId/:clipcardSubscriptionSize?',
        name: 'Staff-ClipcardSubscriptions-summary',
        meta: { title: 'header.clipcardSummaryDetails', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () =>
          import(/* webpackChunkName: "staff-clipcard-subscriptions-summary" */ '../views/clipcards/ClipcardSubscriptionDetails.vue'),
      },
      {
        path: 'clipcards',
        name: 'Staff-Clipcards',
        meta: { title: 'header.clipcard', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "staff-clipcards" */ '../views/clipcards/Clipcards.vue'),
      },
      {
        path: 'clipcard/:clipcardId/:clipcardCenterId/:clipcardSize?',
        name: 'Staff-ClipcardDetails',
        meta: { title: 'header.clipcardDetails', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "staff-clipcardDetails" */ '../views/clipcards/ClipcardDetails.vue'),
      },
      /** PPS payment result */
      {
        path: 'payment-result/:provider',
        name: 'STAFFPaymentResult',
        meta: { title: 'header.staffPaymentResult', access: [UserProfileEnum.StaffLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "staffPaymentResult" */ '../views/payment-providers/PaymentResult.vue'),
      },
      {
        path: '',
        name: 'Staff-Error404',
        meta: { title: 'Not found' },
        component: () => import(/* webpackChunkName: "staff-error404" */ '../views/errors/404.vue'),
      },
    ],
  },

  /** STAFF : Search Members */
  {
    path: '/members',
    name: 'Members',
    meta: {
      banner: 'memberSearchBanner',
      title: 'header.memberSearch',
      navbarName: 'header.memberSearch',
      cy: 'member-search-link',
      sysprop: 'memberSearchPage',
      access: [UserProfileEnum.StaffLevel],
    },
    component: () => import(/* webpackChunkName: "member-search" */ '../views/member-search/member-search.vue'),
  },

  /** MANAGER : Booking Schedule */
  {
    path: '/manager-booking-schedule',
    name: 'ManagerBookingSchedule',
    meta: {
      banner: 'managerBookingScheduleBanner',
      title: 'header.managerBookingSchedule',
      navbarName: 'header.managerBookingSchedule',
      cy: 'manager-booking-schedule-link',
      // sysprop: 'ManagerBookingSchedulePage', TODO: add sysprop so we can enable/disable this page
      access: [UserProfileEnum.ManagerLevel],
    },
    component: () =>
      import(/* webpackChunkName: "manager-booking-schedule" */ '../views/manager-booking-schedule/manager-booking-schedule.vue'),
  },
  /** COMPANY : Billing info */
  {
    path: '/company',
    name: 'Company',
    meta: {
      banner: 'companyBanner',
      title: 'header.companyHomePage',
      cy: 'company-homepage-link',
      access: [UserProfileEnum.CompanyLevel],
    },
    component: () => import(/* webpackChunkName: "company" */ '../views/company/company.vue'),
    children: [
      {
        path: 'billing-info',
        name: 'Company-BillingInfo',
        meta: { title: 'header.summary' },
        props: true,
        component: () => import(/* webpackChunkName: "company-billing-info" */ '../views/company/company-billing-info.vue'),
      },
    ],
  },

  /** JOIN FLOW */
  {
    path: '/join',
    name: 'Join',
    meta: { title: 'header.join', banner: 'joinFlowBanner', reloadAccount: true },
    component: () => import(/* webpackChunkName: "join" */ '../views/join/Join.vue'),
    children: [
      {
        path: 'center',
        name: 'SelectCenter',
        meta: { title: 'header.selectCenter' },
        component: () => import(/* webpackChunkName: "join-selectCenter" */ '../views/join/center/SelectCenter.vue'),
      },
      {
        path: 'plan',
        name: 'SelectPlan',
        meta: { title: 'header.selectPlan', reloadAccount: true },
        component: () => import(/* webpackChunkName: "join-selectPlan" */ '../views/join/plan/SelectPlan.vue'),
      },
      {
        path: 'details',
        name: 'MemberDetails',
        meta: { title: 'header.memberDetails', reloadAccount: true },
        component: () => import(/* webpackChunkName: "join-memberDetails" */ '../views/join/details/MemberDetails.vue'),
      },
      {
        path: 'payment',
        name: 'MembershipPayment',
        meta: { title: 'Membership Payment', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        component: () => import(/* webpackChunkName: "join-membershipPayment" */ '../views/join/payment/MembershipPayment.vue'),
      },
      {
        path: 'questionnaire',
        name: 'MemberShipQuestionnaire',
        meta: { title: 'header.membershipQuestionnaire', access: [UserProfileEnum.MemberLevel], reloadAccount: false },
        component: () =>
          import(/* webpackChunkName: "join-membershipQuestionnaire" */ '../views/join/questionnaire/MembershipQuestionnaire.vue'),
      },
    ],
  },
  {
    path: '/familymembers/:familyMemberId/:familyMemberCenterId',
    name: 'FamilyMembers',
    props: true,
    meta: { title: 'header.familyMembers', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
    component: () => import(/* webpackChunkName: "FamilyMembersBanner" */ '../views/family-members/FamilyMembersBanner.vue'),
    children: [
      {
        path: 'join',
        name: 'FamilyMembers-join',
        props: true,
        meta: { title: 'header.familyMembers', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        component: () => import(/* webpackChunkName: "familyMembers" */ '../views/join/FamilyMembers.vue'),
        children: [
          {
            path: 'center',
            name: 'FamilyMembers-SelectCenter',
            props: true,
            meta: { title: 'header.selectCenter' },
            component: () => import(/* webpackChunkName: "familyMembers-selectCenter" */ '../views/join/center/SelectCenter.vue'),
          },
          {
            path: 'plan',
            name: 'FamilyMembers-SelectPlan',
            props: true,
            meta: { title: 'header.selectPlan', reloadAccount: true },
            component: () => import(/* webpackChunkName: "familyMembers-selectPlan" */ '../views/join/plan/SelectPlan.vue'),
          },
          {
            path: 'details',
            name: 'FamilyMembers-RelationDetails',
            props: true,
            meta: { title: 'header.memberDetails', reloadAccount: true },
            component: () => import(/* webpackChunkName: "familymembers-relationdetails" */ '../views/join/details/MemberDetails.vue'),
          },
          {
            path: 'payment',
            name: 'FamilyMembers-MembershipPayment',
            props: true,
            meta: { title: 'header.membershipPayment', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
            component: () =>
              import(/* webpackChunkName: "familyMembers-membershipPayment" */ '../views/join/payment/MembershipPayment.vue'),
          },
        ],
      },
      {
        path: 'clipcard/:clipcardId/:clipcardCenterId/:clipcardSize?',
        name: 'FamilyMember-ClipcardDetails',
        meta: { title: 'header.clipcardDetails', access: [UserProfileEnum.MemberLevel] },
        props: true,
        component: () => import(/* webpackChunkName: "FamilyMember-ClipcardDetails" */ '../views/clipcards/ClipcardDetails.vue'),
      },
    ],
  },
  /** CREATE LEAD FLOW */
  {
    path: '/create',
    name: 'Create',
    meta: { title: 'header.createAccount' },
    component: () => import(/* webpackChunkName: "create" */ '../views/join/Lead.vue'),
    children: [
      {
        path: 'center',
        name: 'Lead-SelectCenter',
        meta: { title: 'header.selectCenter' },
        component: () => import(/* webpackChunkName: "create-selectCenter" */ '../views/join/center/SelectCenter.vue'),
      },
      {
        path: 'details',
        name: 'Lead-MemberDetails',
        meta: { title: 'header.memberDetails', reloadAccount: true },
        component: () => import(/* webpackChunkName: "create-memberDetails" */ '../views/join/details/MemberDetails.vue'),
      },
    ],
  },
  /** Change Subscription Flow */
  {
    path: '/change/:currentPlanId/:currentPlanCenterId',
    name: 'ChangeSubscription',
    meta: { title: 'header.changeSubscription', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "change" */ '../views/join/Change.vue'),
    children: [
      {
        path: 'plan',
        name: 'Change-SelectPlan',
        meta: { title: 'header.selectPlan', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        props: true,
        component: () => import(/* webpackChunkName: "change-selectPlan" */ '../views/join/plan/SelectPlan.vue'),
      },
      {
        path: 'details',
        name: 'Change-MemberDetails',
        meta: { title: 'Member Details', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        props: true,
        component: () => import(/* webpackChunkName: "change-memberDetails" */ '../views/join/details/MemberDetails.vue'),
      },
      {
        path: 'payment',
        name: 'Change-MembershipPayment',
        meta: { title: 'header.membershipPayment', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        props: true,
        component: () => import(/* webpackChunkName: "change-membershipPayment" */ '../views/join/payment/MembershipPayment.vue'),
      },
      {
        path: 'questionnaire',
        name: 'Change-MemberShipQuestionnaire',
        meta: { title: 'header.membershipQuestionnaire', access: [UserProfileEnum.MemberLevel] },
        props: true,
        component: () =>
          import(/* webpackChunkName: "change-membershipQuestionnaire" */ '../views/join/questionnaire/MembershipQuestionnaire.vue'),
      },
    ],
  },
  /** Buy Addons flow */
  {
    path: '/addon/:currentPlanGlobalId/:currentPlanCenterId',
    name: 'BuyAddon',
    meta: { title: 'header.buyAddons', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "addon" */ '../views/addons/Addon.vue'),
    children: [
      {
        path: 'plan',
        name: 'Addon-SelectPlan',
        meta: { title: 'header.selectAddon', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        props: true,
        component: () => import(/* webpackChunkName: "addon" */ '../views/addons/plan/SelectAddons.vue'),
      },
      {
        path: 'payment',
        name: 'Addon-MembershipPayment',
        meta: { title: 'header.addonPayment', access: [UserProfileEnum.MemberLevel], reloadAccount: true },
        props: true,
        component: () => import(/* webpackChunkName: "addon" */ '../views/addons/payment/AddonPayment.vue'),
      },
    ],
  },
  /*** shopping basket Flow */
  {
    path: '/basket/:shoppingBasketId',
    name: 'ShoppingBasketPayment',
    meta: { title: 'header.shoppingBasketPayment', sysprop: 'shoppingBasketPage', cy: 'shopping-basket-link' },
    props: true,
    component: () => import(/* webpackChunkName: "shoppingBasketPayment" */ '../views/shopping-basket/ShoppingBasket.vue'),
  },
  /*** Debt Flow */
  {
    path: '/debt',
    name: 'DebtPayment',
    meta: { title: 'header.debtPayment', cy: 'debt-link' },
    props: true,
    component: () => import(/* webpackChunkName: "shoppingBasketPayment" */ '../views/debt/Debt.vue'),
  },
  /*** Bring a friend Flow */
  {
    path: '/friend/:refererId/:refererCenterId/:clipcardId/:clipcardCenterId/:clipcardSubId/:checksum',
    name: 'BringAFriend',
    meta: { title: 'header.bringAFriend', access: [UserProfileEnum.MemberLevel] },
    props: true,
    component: () => import(/* webpackChunkName: "bring-a-friend" */ '../views/friend/friend.vue'),
  },
  /*** Update GDPR */
  {
    path: '/gdpr',
    name: 'GDPR',
    meta: { access: [UserProfileEnum.MemberLevel] },
    component: () => import(/* webpackChunkName: "shoppingBasketPayment" */ '../views/gdpr/GDPR.vue'),
  },
  /*** Create lead without join */
  {
    path: '/generate-lead',
    name: 'LeadGeneration',
    meta: { title: 'header.createAccount', hideNavbarMenu: true },
    component: () => import(/* webpackChunkName: "generate-lead" */ '../views/lead-generation/Lead.vue'),
    children: [
      {
        path: 'center',
        name: 'LeadGeneration-SelectCenter',
        meta: { title: 'header.selectCenter', hideNavbarMenu: true },
        component: () => import(/* webpackChunkName: "generate-lead" */ '../views/join/center/SelectCenter.vue'),
      },
      {
        path: 'details',
        name: 'LeadGeneration-MemberDetails',
        meta: { title: 'header.memberDetails', hideNavbarMenu: true, reloadAccount: true },
        component: () => import(/* webpackChunkName: "generate-lead" */ '../views/lead-generation/details/MemberDetails.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
