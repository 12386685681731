import { ActivityModel, AddressModel, DocumentModel, ErrorModel, UrlContentObjArrayModel, UserProfileEnum } from '../../../common/src';
import {
  ConfigModel,
  ConfigWebUserModel,
  PaymentProvidersConfigModel,
  PaymentProvidersEnum,
} from '../../../common/src/models/config.model';
import store from '@/store';
import { passwordStrength } from 'check-password-strength';
import moment from 'moment';
import { Language } from '@/i18n';
import { formatDateToExerp } from './date-utils';
const stateList = require.context('@/assets')('./state-list.json');

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
const CLASS_DEFAULT_IMAGE = '/default_class_3.png';

export class FormField {
  value?: any;
  error?: string;
  checking?: boolean;
  options?: any[];
}

export class FormFields {
  constructor(
    public subscriptionStartDate: FormField = {},
    public email: FormField = {},
    public confirmEmail: FormField = {},
    public password: FormField = {},
    public confirmPassword: FormField = {},
    public salutation: FormField = {},
    public gender: FormField = {},
    public firstName: FormField = {},
    public lastName: FormField = {},
    public birthday: FormField = {},
    public phone: FormField = {},
    public idType: FormField = {},
    public idNumber: FormField = {},
    public passportDate: FormField = {},
    public passportCountry: FormField = {},
    public address1: FormField = {},
    public address2: FormField = {},
    public zipCode: FormField = {},
    public city: FormField = {},
    public country: FormField = {},
    public state: FormField = { options: stateList },
    public ssn: FormField = {},
    public terms: FormField = { value: false },
    public checkbox1: FormField = { value: false },
    public checkbox2: FormField = { value: false },
    public checkbox3: FormField = { value: false },
    public language: FormField = {},
    public allowThirdPartyOffers: FormField = { value: false },
    public allowEmailNewsletters: FormField = { value: false }
  ) {}
}

export function getInvalidFrields(formElements: any) {
  let invalidField: string | undefined = undefined;
  for (const key in formElements) {
    if ((formElements as any)[key].error || (formElements as any)[key].checking) {
      invalidField = key;
      break;
    }
  }
  return invalidField;
}

export function getLookAndFeel(config: ConfigModel | undefined, param: any): string | null {
  // The case where the banner is not set cannot be tested for now.
  // TODO: if there's a page without a banner, it needs to be tested
  /* istanbul ignore next */
  if (!config || !config.lookAndFeel || !param) return null;
  /* istanbul ignore next */
  return (config.lookAndFeel as any)[String(param)] || null;
}

/**
 * Check if an email is valid
 * @param email User email
 * @returns Boolean
 */
export function validateEmail(email: string): boolean {
  /* Same regex that is used in Exerp to validate email */
  const regex = /^[\w!#$%&'*+=?`{|}~^-]+(?:\.[\w!#$%&'*+=?`{|}~^-]+)*@(?:[a-zA-Z\d-]+\.){1,63}[a-zA-Z]{2,63}$/;
  return regex.test(email);
}

export function downloadDocument(documentModel: DocumentModel) {
  const sliceSize = 1024;
  let byteCharacters;
  try {
    byteCharacters = atob(documentModel.data);
  } catch (e) {
    throw new ErrorModel('general.documentFormatError');
  }
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);
    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  const blob = new Blob(byteArrays, { type: documentModel.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = documentModel.name + '';
  link.click();
}

export function isNullOrUndefined(object: any): boolean {
  return object === null || object === undefined;
}

// get the max between a few dates. The first one must not be null or undefined
export function getMaxDate(date: Date, ...date1: (Date | undefined)[]): Date {
  // sort dates (at least the first date is an actual date and not undefined)
  const dates = [date, ...date1].filter(d => d !== undefined).sort((a, b) => a!.getTime() - b!.getTime());
  return dates[dates.length - 1]!; // at least one date is defined, the others were filtered out
}

export function getPasswordStrength(password: string): { strength: number; text: string; cssClass: string } {
  const strength = passwordStrength(password).id;
  let text;
  let cssClass;
  if (strength < 1) {
    text = 'subscription.tooWeak';
    cssClass = 'text-red-500';
  } else if (strength < 2) {
    text = 'subscription.weak';
    cssClass = 'text-yellow-600';
  } else if (strength < 3) {
    text = 'subscription.medium';
    cssClass = 'text-blue-500';
  } else {
    text = 'subscription.strong';
    cssClass = 'text-green-500';
  }
  return { strength, text, cssClass };
}

export function getClassImage(activity: ActivityModel): string {
  return (
    store.state.config!.lookAndFeel.classImages[activity.id] ||
    (activity.name && store.state.config!.lookAndFeel.classImages[activity.name.trim().toLowerCase()]) ||
    store.state.config!.lookAndFeel.classImages['default'] ||
    CLASS_DEFAULT_IMAGE
  );
}

export function getAddonImage(addonName: string | undefined) {
  if (!addonName) return store.state.config!.lookAndFeel.addonImages['default'];

  const formattedAddonName = addonName.trim().toLowerCase();
  return store.state.config!.lookAndFeel.addonImages[formattedAddonName] || store.state.config!.lookAndFeel.addonImages['default'];
}

export function formatAmountToLocale(amount: string | number, currency: string | undefined): string {
  if (!currency || String(currency) === 'undefined') {
    currency = 'XXX';
  }
  const display = new Intl.NumberFormat(store.state.currentLocale!.numberFormat, {
    style: 'currency',
    currency,
  }).format(Number(amount || 0));
  return display.replace('XXX', '').trim();
}

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function isWrrongCenter(centerId: number) {
  if (!centerId) {
    return true;
  }
  return store.state.user && !store.state.config!.features.allowSubscriptionSaleOutsideHomeCenter && centerId !== store.state.user.centerId;
}

// This should only be used for joinFlow, Baf, Weblead, and new subscription (Not for the profile)
// in the profile, config.minimumAge is not used and will always be undefined, so that's cool
export function getMinimumDateForCreationNewPerson(config: ConfigWebUserModel) {
  if (config.minimumAge) {
    return moment().subtract(config.minimumAge, 'years').format('YYYY-MM-DD');
  } else {
    return getToday();
  }
}

export function getToday() {
  return moment().format('YYYY-MM-DD');
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function checkIfStaffActingForMember(selectedMemberId: number | undefined): boolean {
  return (
    store.state.user!.accessLevel.includes(UserProfileEnum.StaffLevel) &&
    selectedMemberId !== undefined &&
    Number(selectedMemberId) !== Number(store.state.user!.userId)
  );
}

export function staffIsAllowedToPay(listOfPaymentProviders: PaymentProvidersEnum[]): boolean {
  let returnedValue = false;
  for (const paymentProvider of listOfPaymentProviders) {
    if (store.state.config!.paymentProvidersConfig[paymentProvider]!.allowedForStaff) {
      returnedValue = true;
      break;
    }
  }
  return returnedValue;
}

export function filterPaymentProviderListByProfile(
  paymentProvidersConfig: PaymentProvidersConfigModel,
  paymentProviderList: PaymentProvidersEnum[],
  selectedMemberId: number | false
): PaymentProvidersEnum[] {
  const filteredListOfPaymentProviders: PaymentProvidersEnum[] = [];

  // Check first if the member is a staff member
  for (const paymentProvider of paymentProviderList) {
    if (selectedMemberId === false) {
      filteredListOfPaymentProviders.push(paymentProvider);
    } else if (paymentProvidersConfig[paymentProvider]!.allowedForStaff && checkIfStaffActingForMember(selectedMemberId)) {
      filteredListOfPaymentProviders.push(paymentProvider);
    } else if (paymentProvidersConfig[paymentProvider]!.allowedForMember && !checkIfStaffActingForMember(selectedMemberId)) {
      filteredListOfPaymentProviders.push(paymentProvider);
    }
  }

  return filteredListOfPaymentProviders;
}

export function toTitleCase(input: string): string {
  if (input) {
    const convertedCase = input.replace(/(\p{L}+)/gu, match => match.charAt(0).toUpperCase() + match.slice(1));
    return convertedCase;
  }
  return '';
}

export function getLanguage(code?: string): Language {
  let result: Language = store.state.availableLocales[0];
  store.state.availableLocales.forEach(lang => {
    if (lang.code === code) {
      result = lang;
    }
  });
  return result;
}

export function floorNumberToPrecision(number: number, precision: number): number {
  return Math.floor(number * Math.pow(10, precision)) / Math.pow(10, precision);
}

export interface TabElement {
  id: string;
  name: string;
  label: string;
  icon: string;
  condition?: () => Promise<boolean>;
}
// AddressModel toString
export function addressToString(address: AddressModel): string {
  return (
    (address.address1 ? address.address1 + ', ' : '') +
    (address.address2 ? address.address2 + ', ' : '') +
    (address.address3 ? address.address3 + ', ' : '') +
    (address.zip ? address.zip + ' ' : '') +
    (address.zipName ? address.zipName + ', ' : '') +
    (address.country ? address.country : '')
  );
}

export function blurAllFormElements(element: HTMLElement) {
  // in IOS when the popup is open the date time selection is automatically focused and the calendar opens:
  const formElements = element.querySelectorAll('input, select, checkbox');
  for (const formElement of formElements) {
    (formElement as HTMLInputElement | HTMLSelectElement).blur();
  }
}

export function sanitizeHtml(html: string): string {
  html = stripUnwantedTags(html, 'script'); // remove script tags
  html = stripUnwantedTags(html, 'iframe'); // remove iframes
  return html;
}

export function stripUnwantedTags(html: string, tagname: string): string {
  const div = document.createElement('div');
  div.innerHTML = html;
  const scripts = div.getElementsByTagName(tagname);
  let i = scripts.length;
  while (i--) {
    (scripts[i].parentNode as HTMLElement).removeChild(scripts[i]);
  }
  return div.innerHTML;
}

export function calculateAge(birthday: string): number {
  const birthDate = new Date(birthday);
  const ageDifMs = new Date().getTime() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  const age = (Math.abs(ageDate.getUTCFullYear() - 1970) * 12 + ageDate.getUTCMonth()) / 12;
  return Math.round(age * 100) / 100;
}

export function b64toBlob(b64Data: string, contentType: string) {
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function filterOutContentByUrl(url: string, currentRoute: string): boolean {
  let isVisible = false;
  url = url.trim();
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  if (currentRoute.toLowerCase().includes(url.toLowerCase())) {
    isVisible = true;
  }
  return isVisible;
}
