import {
  LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY,
  LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER,
  LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN,
  LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN,
  LOCAL_STORAGE_AUTHENTICATION_SSO_USER,
  LOCAL_STORAGE_AUTHENTICATION_TOKEN,
} from '@/services/user.service';
import store from '@/store';
import axios, { AxiosRequestConfig } from 'axios';
import { SSO_TOKEN_HEADER, SSO_USER_HEADER, ErrorModel, SSO_COMPANY_HEADER,SSO_HEADER_TYPE_HEADER, SSO_MAPI_TOKEN_HEADER } from '../../../common/src';

const TIMEOUT = 1000000;

const onRequestSuccess = (config: AxiosRequestConfig): AxiosRequestConfig => {
  let token: string | null = null;
  if (config.headers!['SSO'] === 'true') {
    token = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN);
  } else {
    token = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN) || localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN);
  }
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  config.headers!['x-referer'] = window.location.href;
  if (store.state.currentLocale) {
    config.headers!['Accept-Language'] = store.state.currentLocale!.locale;
  }

  const ssoToken = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN);
  const ssoMapiToken = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN);
  const ssoUser = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_USER);
  const ssoCompany = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY);
  const ssoHeader = sessionStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER);
  if (ssoToken && ssoUser) {
    config.headers![SSO_TOKEN_HEADER] = ssoToken;
    config.headers![SSO_MAPI_TOKEN_HEADER] = ssoMapiToken;
    config.headers![SSO_USER_HEADER] = ssoUser;
    config.headers![SSO_COMPANY_HEADER] = ssoCompany!; // TODO DEV-45555 For some reason this one is never null (Like the header maybe ?)
    config.headers![SSO_HEADER_TYPE_HEADER] = ssoHeader!;
  } else {
    delete config.headers![SSO_TOKEN_HEADER];
    delete config.headers![SSO_MAPI_TOKEN_HEADER];
    delete config.headers![SSO_USER_HEADER];
    delete config.headers![SSO_COMPANY_HEADER];
    delete config.headers![SSO_HEADER_TYPE_HEADER];
  }
  config.timeout = TIMEOUT;
  config.url = `${process.env.VUE_APP_BACKEND_API_URL}/${config.url}`;
  return config;
};

const setupAxiosInterceptors = (onUnauthenticated: () => void): void => {
  const onResponseError = (err: any) => {
    const status = err.status || (err.response && err.response.status);

    let error;
    // we can't test all these errors, but we should have them here .. :(
    /* istanbul ignore next */
    if (err.response?.data && (err.response.data.errorMessage || err.response.data.errorCode || err.response.data.httpCode)) {
      error = err.response.data;
    } else {
      // TODO: can we test HTTP errors somehow ?
      /* istanbul ignore next */
      error = new ErrorModel('general.httpError', status);
    }
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(error);
  };
  /* istanbul ignore next */
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(res => res, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
