export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
  "numberFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
  "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
  "dateFormatFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
  "longName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ltr"])},
  "order": 1,
  "general": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "fromCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "toCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "andMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and more"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "TUESDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "WEDNESDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "THURSDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "FRIDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "SATURDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "FOR-WEEK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["For one week"]), _normalize(["for ", _interpolate(_named("count")), " weeks"])])},
    "FOR-MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["for one month"]), _normalize(["for ", _interpolate(_named("count")), " months"])])},
    "FOR-YEAR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["for one year"]), _normalize(["for ", _interpolate(_named("count")), " years"])])},
    "FOR-DAY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["for one day"]), _normalize(["for ", _interpolate(_named("count")), " days"])])},
    "PER-YEAR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["per year"]), _normalize(["every ", _interpolate(_named("count")), " years"])])},
    "PER-MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["per month"]), _normalize(["every ", _interpolate(_named("count")), " months"])])},
    "PER-WEEK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["per week"]), _normalize(["every ", _interpolate(_named("count")), " weeks"])])},
    "PER-DAY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["per day"]), _normalize(["every ", _interpolate(_named("count")), " days"])])},
    "session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["one session"]), _normalize([_interpolate(_named("count")), " sessions"])])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club"])},
    "authenticationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Error"])},
    "serverError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Error"])},
    "httpError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem while connecting to the server"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Error"])},
    "incompleteConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Configuration"])},
    "incorrectColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Color Values"])},
    "errorLoadingConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Loading Configuration"])},
    "errorParsingConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Parsing Configuration"])},
    "Unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to do this action"])},
    "unauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unathorized"])},
    "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Token"])},
    "centerAddressNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center address not found"])},
    "incorrectCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Currency"])},
    "personNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person not found"])},
    "documentFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document format error"])},
    "errorWhileSearchingPersons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while searching for persons"])},
    "errorLoadingCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading centers"])},
    "errorGettingPersonRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting person relations"])},
    "errorLoadingStaffGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading staff groups"])},
    "errorLoadingRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading roles"])},
    "errorLoadingScopes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading scopes"])},
    "unknownCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Center"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "insteadOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instead of"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "applicableTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["applicable taxes"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating..."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "pleaseTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
    "typeToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
    "thisFieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* This field is required"])},
    "clickToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to select"])},
    "clickToRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to remove"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "latestTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest time"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "instructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
    "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "mostPopular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOST POPULAR"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "errorSavingPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving payment"])},
    "errorRetrievingPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error retrieving payment"])},
    "noPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to access this page"])},
    "waitForRedirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting, please wait.."])},
    "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
    "selectedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected user"])},
    "continueSelecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Selecting"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "selectCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a center"])},
    "selectCenterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a center first"])},
    "pleaseSelectCenterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a center first"])},
    "semesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semesters"])},
    "searchForAllSemesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for all semesters"])},
    "searchForAllTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for all types"])},
    "familyRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Relations"])},
    "searchForAllMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for all members"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date from"])},
    "datesFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates from"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date to"])},
    "withEarliestTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". With earliest time"])},
    "andLatestTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", and latest time"])},
    "weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekdays"])},
    "allDaysOfTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All days of the week"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item(s)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "pleaseSelectDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a date range"])},
    "wrongDateValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before today"])},
    "negativeDateInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end date cannot be before the start date"])},
    "ages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ages"])},
    "minAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min age (years)"])},
    "maxAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max age (years)"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort By"])},
    "alphabeticalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical order"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "noResultsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "shoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Basket"])},
    "continueToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to Payment"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "startingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting from"])},
    "addFamilyMembersOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add family members on your"])},
    "profilePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile page"])},
    "bookFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book for "])},
    "addToCartFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart for "])},
    "noProductsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products available"])},
    "productAlreadyOwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have the product"])},
    "viewSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view schedule"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting ..."])},
    "purchaseRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase required"])},
    "MALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "FEMALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "placeHolders": {
      "yourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])},
      "yourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password"])},
      "yourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
      "pwdPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456"])},
      "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo code"])}
    },
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "unselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unselect"])}
  },
  "header": {
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "clipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcards"])},
    "clipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard"])},
    "clipcardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard Details"])},
    "staffPaymentResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPS Payment Result"])},
    "resourceBookingClipcardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource booking clipcard details"])},
    "resourceBookingClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource booking clipcards"])},
    "classBookingClipcardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class booking clipcard details"])},
    "classBookingClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class booking clipcards"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "clipcardSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard Subscriptions"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
    "selectCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Center"])},
    "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Plan"])},
    "memberDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Details"])},
    "membershipQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Questionnaire"])},
    "membershipPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Payment"])},
    "addonPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon Payment"])},
    "selectAddon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select add-ons"])},
    "afterJoinQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After join questionnaire"])},
    "familyMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family members"])},
    "bringAFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring a Friend"])},
    "changeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Subscription"])},
    "openMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open main menu"])},
    "openLanguageMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open language menu"])},
    "openUserMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open user menu"])},
    "resourcebooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource booking"])},
    "memberSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member search"])},
    "managerBookingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Booking schedule"])},
    "shoppingBasketPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Basket Payment"])},
    "debtPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay debt"])},
    "SSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting.. "])},
    "ssoTokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO Token Error"])},
    "clipcardSummaryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard Summary Details"])},
    "companyHomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "buyAddons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Addons"])}
  },
  "footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2022"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "gtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTC"])}
  },
  "login": {
    "loginPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "loginPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to continue with your booking."])},
    "signInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to continue"])},
    "signInSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to access your account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait .."])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
    "errorAuthenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or password incorrect"])},
    "errorAuthenticatingWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or password incorrect"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new password"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
    "dontHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet ?"])},
    "createANewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
    "loginToFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login to your account or create a new one to be able to use your friend's code for your free session."])},
    "errorGettingUserByExternalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting user by external id"])},
    "errorGettingUserLockoutSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting user session"])},
    "errorResettingUserLockoutSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error clearing user session"])},
    "accountLockedOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong credentials and your account is temporary blocked for ", _interpolate(_named("lockoutPeriod")), " minute(s)"])},
    "errorSavingLockoutSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving user session"])},
    "errorCreatingUserLockoutSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating user session"])}
  },
  "dashboard": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome, ", _interpolate(_named("name"))])},
    "filtersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "mySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My schedule"])},
    "stateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "bookNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book new"])},
    "recentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent activities"])},
    "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
    "loadingMyScheduleEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have nothing scheduled"])},
    "nothingScheduledForFilteredActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing scheduled for the selected filters"])},
    "noRecentActivitiesForSelectedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recent activities for the selected filters"])},
    "noRecentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no recent activities"])},
    "noMoreActivitiesToLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more activities to load"])},
    "filterByActivityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity type"])},
    "Camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp"])},
    "allMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All members"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room:"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat:"])}
  },
  "booking": {
    "addToWaitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to waiting list"])},
    "confirmBook": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to book ", _interpolate(_named("name")), " on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "?"])},
    "confirmWaitingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to be added to the waiting list of ", _interpolate(_named("name")), " on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "?"])},
    "confirmCancelBooking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to cancel ", _interpolate(_named("name")), " on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "?"])},
    "bookASpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a spot"])},
    "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel booking"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "classNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity not available for selected participant"])},
    "illegalBookingState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illegal Booking State"])},
    "errorWhileCancelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while cancelling"])},
    "errorGettingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting activities"])},
    "errorGettingParticipations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting participations"])},
    "errorGettingAttends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting Attends"])},
    "errorGettingClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting classes"])},
    "errorGettingInstructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the instructors"])},
    "errorGettingCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the centers"])},
    "errorGettingResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the resources"])},
    "errorGettingBookingParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the booking participants"])},
    "errorAddingBookingParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while adding the participant to the booking"])},
    "tooLateToCancelByCustomer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It’s too late to cancel your booking ", _interpolate(_named("activityName")), " ", _interpolate(_named("timeDate"))])},
    "tooLateToCancelByCenter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It’s too late to cancel your booking ", _interpolate(_named("activityName")), " ", _interpolate(_named("timeDate"))])},
    "participationAlreadyCancelled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The booking ", _interpolate(_named("activityName")), " ", _interpolate(_named("timeDate")), " is already cancelled"])},
    "noPrivilegeForParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current plan does not allow you to book this activity"])},
    "tooEarlyToBookParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has not opened for this activity yet"])},
    "participationPersonBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't create a booking while being blocked"])},
    "participationPersonBlacklisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't create a booking while being blacklisted"])},
    "inAdvanceLimitBookingRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are being sanctioned, and resticted from booking"])},
    "fullBookingRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are being sanctioned, and resticted from booking"])},
    "cannotParticipateInFreezePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current plan does not allow you to book this activity"])},
    "participantAlreadyBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant is already occupied"])},
    "cannotGetBookingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting booking details"])},
    "errorWhileCheckingBookingPrivilege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the booking privileges"])},
    "errorCreatingStaffBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating the booking"])},
    "errorUpdatingStaffBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating the booking"])},
    "errorDeletingStaffBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while deleting the booking"])},
    "listsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class is full"])},
    "seatNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat not available"])},
    "errorWhileGettingSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading the classe's seats"])},
    "errorGettingBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the booking details"])},
    "errorWhileBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while booking"])},
    "errorWhileSearchingForPersonParticipations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while searching for person participations"])},
    "tooLateToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking can no longer be created for this activity"])},
    "BOOKED_NO_SHOW_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No-Show"])},
    "BOOKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked"])},
    "TENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentative booking"])},
    "loadingInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading interests ..."])},
    "loadingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading activities ..."])},
    "loadingInstructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading instructors ..."])},
    "loadingSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading seats ..."])},
    "BOOKED_IN_WAITING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No-Show"])},
    "CANCELLED_NO_PRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Privilege"])},
    "OVERBOOKED_WAITINGLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting list"])},
    "ATTEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "PARTICIPATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation"])},
    "noActivitiesForFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No activities for the selected filter"])},
    "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
    "instructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
    "unnamedCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-----"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left"])},
    "classFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class full"])},
    "waitingListFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting list full"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "classCapacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class capacity"])},
    "waitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting list"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm booking"])},
    "checkingSeatAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking seat's availability"])},
    "noSeatAddWaitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No seat available. Add to waiting list ?"])},
    "confirmSeatOrPickOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm selected seat or chosse another one"])},
    "selectAndConfirmSeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a seat and confirm"])},
    "noSeatsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No seats available to book"])},
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom +"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom -"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "buyPrivilegeModal": {
      "purchaseRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase required"])},
      "purchaseRequiredDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not currently have any sessions on your account. Would you like to purchase a session?"])},
      "purchaseClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase clipcards "])},
      "updateMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update membership"])},
      "buyNewMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy new membership"])},
      "selectMembershipToUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the membership to update"])},
      "noMembershipAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No membership available"])},
      "cantBookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current subscriptions don't allow you to book this activity"])},
      "cantBookActivityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't book the activity"])}
    }
  },
  "clipcards": {
    "buyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy New Clipcards"])},
    "selectClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select product"])},
    "selectYourClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Your Clipcards"])},
    "errorGettingClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the clipcards"])},
    "invalidClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Clipcard"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "noClipcardsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Clipcard available for the current user"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid"])},
    "readAndAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
    "clubPolicies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club policies"])},
    "loadingPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading payment.."])},
    "emptyClipcardList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clipcard available"])},
    "clipsLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clips left"])},
    "confirmPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "pleaseSelectTheNumberOfClips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the number of clips"])},
    "pleaseConfirmThePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm the price"])},
    "errorLoadingClipcardsWhenNoPrivilege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not able to book at the moment with the selected product. Please select another product"])},
    "errorUpdatingClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating the clipcards"])},
    "errorSettingRefererClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while setting the referer for the clipcards"])},
    "priceTooHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price too high"])},
    "priceTooLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price too low"])},
    "priceRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price required"])},
    "cannotRaisePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot raise the price"])},
    "cannotReducePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot lower the price"])},
    "cannotChangePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot change the price"])},
    "cannotOverrideThePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot override the price"])},
    "errorGettingClipcardsForAnonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading the clipcards"])},
    "clipCardNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard not allowed"])}
  },
  "clipcardsubscription": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard Subscriptions"])},
    "noSubscriptionAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Subscription available for the selected center"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "confirmPurchaseOfSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm the purchase of the subscription"])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "errorGettingClipcardSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the clipcard subscriptions"])},
    "emptyShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket is empty"])},
    "invalidClipcardSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid clipcard subscription"])},
    "shoppingBasketAlreadyPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket already paid"])},
    "shoppingBasketNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket is not active"])},
    "toasterSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Basket sent to member"])}
  },
  "products": {
    "errorGettingProductsForBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the products for the booking"])}
  },
  "profile": {
    "authenticationSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication info"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "thirdPartyOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third party offers"])},
    "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSN"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "editInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit information"])},
    "changeEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email address"])},
    "newEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email address"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password again"])},
    "passwordsMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords match"])},
    "myPaymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My payment information"])},
    "myPaymentAgreements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My payment agreements"])},
    "noPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment agreement"])},
    "updateSelectedPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update selected payment agreement"])},
    "changeYourPaymentAgreementExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your payment agreement information by filling in your credit card details on the next page."])},
    "myCardsOnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My cards on file"])},
    "noCardsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cards found"])},
    "mySubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subscriptions"])},
    "noSubscriptionFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subscriptions found for the user"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "memberId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Id"])},
    "subscriptionStatuses": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVE"])},
      "ENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENDED"])},
      "FROZEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FROZEN"])},
      "WINDOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WINDOW"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATED"])}
    },
    "freezePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze period"])},
    "myClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My clipcards"])},
    "manageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
    "manageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage subscription"])},
    "buyAddon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy new addon"])},
    "changeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change subscription"])},
    "freezeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze subscription"])},
    "terminateSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate subscription"])},
    "terminateHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate your subscription "])},
    "terminateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate subscription"])},
    "selectTerminationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select termination date"])},
    "lastPayingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last paying date"])},
    "cancelDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation date"])},
    "regretCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regret"])},
    "errorLoadingSuggestedTerminationDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading suggested termination dates"])},
    "confirmRegretCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm regret"])},
    "confirmRegretCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you confirm you want to keep your membership ", _interpolate(_named("membershipName")), " instead of cancelling it on the ", _interpolate(_named("membershipCancellationDate")), " ?"])},
    "freezeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create freeze"])},
    "freezeHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can freeze your subscription ", _interpolate(_named("subscriptionName")), " between ", _interpolate(_named("freezeMinimumDuration")), " ", _interpolate(_named("freezeMinimumDurationUnit")), " and ", _interpolate(_named("freezeMaximumDuration")), " ", _interpolate(_named("freezeMaximumDurationUnit"))])},
    "subState": {
      "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "AWAITING_ACTIVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "UPGRADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "DOWNGRADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "EXTENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "TRANSFERRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "REGRETTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "BLOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "CHANGED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "freezeDurationUnits": {
      "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["day"]), _normalize(["days"])])},
      "weeks": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["week"]), _normalize(["weeks"])])},
      "months": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["month"]), _normalize(["months"])])},
      "years": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["year"]), _normalize(["years"])])}
    },
    "freezeDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From date"])},
    "freezeDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To date "])},
    "freezeSummaryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment summary"])},
    "freezePeriodPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period price"])},
    "freezeFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze fee"])},
    "confirmFreezeEndingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm freeze operation"])},
    "confirmFreezeEndingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to suspend freeze for ", _interpolate(_named("membershipName")), " that starts from ", _interpolate(_named("freezeStartDate")), " to ", _interpolate(_named("freezeEndDate")), "?"])},
    "plannedFreezeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned freeze"])},
    "freezeCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "freezeStopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
    "freezeStopDisabledHoverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not able to stop an ongoing freeze"])},
    "noPaymentProviderSetupForFreezeOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment provider setup for freeze operation"])},
    "payFreezeFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay freeze fee"])},
    "payFreezeFeeWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay freeze fee with ", _interpolate(_named("provider"))])},
    "cannotGetFreezeAvailableIntervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get details about freeze available intervals"])},
    "freezeHintTitleShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show guidelines"])},
    "freezeHintTitleHide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide guidelines"])},
    "freezeExistingFreezesWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that you cannot freeze during existing freeze periods"])},
    "offerMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Instead of terminating your ", _interpolate(_named("subscriptionName")), ", we would like to offer you a great deal on our subscriptions. Would you like to see the offer ?"])},
    "offerButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See offer"])},
    "declineOfferButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline offer"])},
    "buyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy new"])},
    "noClipcardsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clipcards found for the user"])},
    "noInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information to show"])},
    "selectATab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a tab"])},
    "defaultPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "tabs": {
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My personal information"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subscriptions"])},
      "billingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My billing information"])},
      "clipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My clipcards"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My family"])},
      "bringAFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring a friend"])}
    },
    "myFamily": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My family"])},
      "addRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "addSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subscription"])},
      "createFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create family"])},
      "noFamilyFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No family found for the user"])},
      "primaryMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary member"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
      "CHILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHILD"])},
      "SPOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOUSE"])},
      "PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTNER"])},
      "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHER"])},
      "createFamilyPopupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create family"])},
      "createFamilyPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name of the family"])},
      "createFamilyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "createFamilyAbortButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
      "createFamilySuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "createFamilySuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family created successfully"])},
      "createFamilyCloseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "pleaseFillOutAllFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out all fields"])},
      "addChildPopupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add child"])},
      "editChildPopupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit child"])},
      "addChildPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your child information"])},
      "editChildPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your child information"])},
      "firstNameInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "genderInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "lastNameInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "birthDateInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
      "addChildButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "editChildButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "addChildAbortButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
      "addChildSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "editChildSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "addChildCloseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "addChildSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child added successfully"])},
      "editChildSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child updated successfully"])},
      "addChildErrorEmptyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all the fields"])},
      "errorGettingFamilyProfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting family profiles"])},
      "errorGettingFamilyKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting family information"])},
      "errorCreatingFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating family"])},
      "errorCreatingChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating child"])},
      "errorEmptyChildReturned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child not found"])},
      "errorAddingFamilyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while adding family member"])},
      "errorCreatingFamilyRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating family relation"])},
      "errorCreatingParentRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating parent relation"])},
      "ageRestrictionLimitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child exceeds age limitation"])},
      "genderMayNotBeUndefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender cannot be undefined"])},
      "wrongFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong family"])},
      "noFamilyMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No family members"])},
      "wrongFamilyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong family member"])},
      "childTooOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child too old"])},
      "childTooYoung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child too young"])},
      "childTooOldOrYoung": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Children can be added between the ages of ", _interpolate(_named("childMinimumAge")), " ", _interpolate(_named("units")), " and ", _interpolate(_named("childMaximumAge")), " years"])},
      "childBirthDateInFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid, past birth date"])}
    },
    "myBillingInfo": {
      "createDDPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create direct debit payment agreement"])},
      "createCCPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create credit card payment agreement"])},
      "createInvoicePaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoice payment agreement"])},
      "createCCPAPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment agreement"])},
      "createDDPAPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create ACH payment agreement"])},
      "createInvoicePAPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invoice payment agreement"])},
      "updateDDPAPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update ACH payment agreement"])},
      "updateInvoicePAPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update invoice payment agreement"])},
      "createDDPAPopupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your bank account details"])},
      "updateDDPAPopupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your bank account details"])},
      "updateInvoicePAPopupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validating this form will create an invoice payment agreement. Do you confirm ?"])},
      "createCCPAPopupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your credit card"])},
      "createInvoicePAPopupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validating this form will create an invoice payment agreement. Do you confirm ?"])},
      "updatePaWithCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update with CC"])},
      "updatePaWithACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update with ACH"])},
      "updatePaWithInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update with invoice"])}
    },
    "qualitop": {
      "claimMoneyBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Qualitop money back"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUALITOP CASH BACK"])},
      "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality label for fitness and health promotion"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitop is a label that is awarded to high quality health and fintess centers. Pyregym has been tested and certified according to the Qualitop criteria"])},
      "fillFormPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the form below to receive a financial contribution from your health insurance company"])},
      "healthInsuranceRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health insurance refund"])},
      "healthInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health insurance"])},
      "supplementaryInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementary insurance"])},
      "insuranceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance number"])},
      "endNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only the years in which you paid a subscription are shown. Exception annual subscription : Due to the lockdown, you may not have had to pay a subscription, which is why no form can be generated."])},
      "selectYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the year"])},
      "generateForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate form"])},
      "errorMandatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all the mandatory fields"])},
      "extractIdNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extract id not found"])},
      "noExtractFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No extract found"])},
      "emptyExtractFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty extract found"])},
      "errorRunningExtract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while running extract"])},
      "languageNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language not supported"])}
    }
  },
  "basket": {
    "shoppingBasketHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
    "iHaveAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
    "cannotCreateShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot create shopping basket"])},
    "cannotAddClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add clipcard"])},
    "cannotAddServiceProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add service product"])},
    "cannotAddCourseClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add the course to the shopping basket"])},
    "errorGettingShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting shopping basket"])},
    "cannotAddSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add subscription"])},
    "loadingClipcardSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading clipcard subscriptions"])},
    "cannotAddClipcardSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add clipcard subscription"])},
    "cannotStartExternalPaymentSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot start external payment session"])},
    "cannotAddChangeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot change subscription"])},
    "cannotBuyOutsideHomeClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot buy outside your home club"])},
    "subscriptionOfSameType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a subscription of this type"])},
    "addonNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon not available"])},
    "ownerBelowMinimumAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member is below the minimum age"])},
    "productPurchaseAllowedQuantityExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of products you can buy"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "loginPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login to buy products"])},
    "shoppingBasketEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket is empty"])},
    "shoppingBasketAlreadyPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket is already paid"])},
    "shoppingBasketIsNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket is not active"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "shoppingBasketItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping basket items"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "noPaymentProviderAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment provider available"])},
    "customPaymentMethodIdMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom payment method id missing"])},
    "errorsInShoppingBasketElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are some errors on the shopping basket elements, try reloading the page"])},
    "frequencyRestrictionMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add clipcard, please remove clipCard from summary"])},
    "clipcardPurchaseLimitExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please remove clipcard"])},
    "cannotAddAddonsSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add addons to shopping basket"])}
  },
  "debt": {
    "payDebtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment summary"])},
    "totalAmountOfDebtLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount of debt"])},
    "totalAmountOfInvoicesDoesNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount of invoices does not match"])},
    "noPayableDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payable debt"])},
    "amountExceedsPayableDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount exceeds payable debt"])},
    "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
    "missingParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing parameters"])}
  },
  "payment": {
    "loadingContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Contract"])},
    "contractSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract summary"])},
    "clipCardSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard summary"])},
    "removeClipCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove Clipcard"])},
    "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment plan"])},
    "monthlyPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly payment"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "firstDeductionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First deduction date"])},
    "upfrontPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upfront payment"])},
    "initialPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial period"])},
    "adminFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin fees"])},
    "proRata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Rata"])},
    "totalTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TAX"])},
    "plusApplicableTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Plus Applicable Taxes"])},
    "changeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change amount"])},
    "changeFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change fee"])},
    "changeVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change vat"])},
    "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund amount"])},
    "currentSubscriptionWillNotEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchasing this subscription will not end your current subscription(s)"])},
    "changeOfPAMightChangeYourDeductionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of payment agreement might change your deduction date"])},
    "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
    "noCCPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No CC payment method available"])},
    "noPaymentMethodsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment methods available"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
    "directDebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Debit (bank account)"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "invoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will create an invoice payment agreement"])},
    "selectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "ddguaranteetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD guarantee text"])},
    "RoutingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing number"])},
    "AccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "AccountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account type"])},
    "AccountHolderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "BankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
    "BankRegNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Reg Number"])},
    "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "BranchNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Number"])},
    "ControlDigits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control Digits"])},
    "ExtraInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Info"])},
    "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking"])},
    "savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savings"])},
    "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
    "paymentResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Result"])},
    "paymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
    "paymentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Id"])},
    "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Id"])},
    "resultCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result code"])},
    "checkingPaymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking payment status"])},
    "checkingThePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the payment... "])},
    "loadingPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading payment.."])},
    "Clipcard Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard Purchase"])},
    "Resource booking purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource booking purchase"])},
    "Class booking purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class booking purchase"])},
    "Course Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Booking"])},
    "Camps Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps Booking"])},
    "Update Payment Agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Payment Agreement"])},
    "Create Payment Agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment Agreement"])},
    "Purchase Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Subscription"])},
    "Add Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subscription"])},
    "Payment of debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment of debt"])},
    "Top up account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up account"])},
    "Change Membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Membership"])},
    "Freeze subscription fee payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze subscription fee payment"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Error"])},
    "couldNotGetPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get payment"])},
    "couldNotGetInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not download the invoice"])},
    "moreThan3months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't select more than 3 months in total"])},
    "errorPPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to PPS"])},
    "errorTamara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to Tamara"])},
    "errorTabby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to Tabby"])},
    "errorPayTabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to PayTabs"])},
    "errorSpotii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to Spotii"])},
    "errorEASYPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to Easypay"])},
    "errorAdyen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to Adyen"])},
    "errorExerpDD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while validating the bank information"])},
    "errorAuthorisingTamaraOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while authorising Tamara order"])},
    "adyenProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adyen encoutred a problem"])},
    "paymentProcessProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment process encoutred a problem"])},
    "errorCaptureEASYPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while capturing payment on Easypay"])},
    "errorCaptureTABBY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while capturing payment on Tabby"])},
    "errorGettingPaymentInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting payment infos"])},
    "errorRegisteringPaymentPleaseContactClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while registering payment, please contact the club"])},
    "paymentUnsuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment unsuccessfull"])},
    "paymentNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment not completed"])},
    "cannotAddJournalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot register the payment. Please contact your club"])},
    "noClearingHouseFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clearing house found"])},
    "noCardsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cards found"])},
    "cannotUpdatePaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update payment agreement"])},
    "errorNoPProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment provider not enabled"])},
    "paymentAgrrementNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment agreement not found"])},
    "incorrectToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect token"])},
    "cannotMakeCardOnFilePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot make card on file payment"])},
    "cardOnFileLimitExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card on file limit exceeded"])},
    "cardOnFileNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card on file not available"])},
    "illegalPaymentAgreementState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illegal payment agreement state"])},
    "couldNotGetAccountOverView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not retrieve account information"])},
    "incorrectPaymentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect payment id"])},
    "doubleCheckedAmountDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double checked amount doesn't match"])},
    "noPayableDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payable debt"])},
    "tooManyInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many invoices"])},
    "incorrectNumberOfInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect number of invoices"])},
    "downloadingInvoicesIsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading invoices is disabled"])},
    "invoiceNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice not found"])},
    "couldNotRetrieveInvoiceForTheUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not retrieve invoice for the user"])},
    "adyenPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adyen Payment"])},
    "adyenPayementAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adyen Payment Agreement"])},
    "adyenPayementCreditCardNameOverride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payWith-paymentddagreementwithoutpaymentprovider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with External validation"])},
    "payWith-pps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with Priority Payment System"])},
    "payWith-adyen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with Adyen"])},
    "payWith-easypay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with EasyPay"])},
    "payWith-pcipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with PCI Pal"])},
    "payWith-tamara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with installments using Tamara"])},
    "payWith-tabby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with installments using Tabby"])},
    "payWith-spotii": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with installments using Spotii"])},
    "payWith-paytabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit / Debit Card Payment using PayTabs"])},
    "payWithNewCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with a new Credit Card"])},
    "preferredPayementMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECOMMENDED"])},
    "errorGettingPaymentInfoForPCIPal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting payment info for PCI Pal"])},
    "errorGettingPaymentInfoForPayTabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting payment info from PayTabs"])},
    "errorGettingPaymentStatusInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting payment status infos"])},
    "amountNotANumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount returned is not a number"])},
    "errorGettingPaymentReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting payment reference"])},
    "errorPCIPal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while connecting to PCI Pal"])},
    "NotImplemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not implemented"])},
    "sendShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send shopping basket"])},
    "sendShoppingBasketWhenNoMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't send shopping basket because no email is set"])},
    "accountGlobalIdNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global account id not found"])},
    "errorSettingOtherPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while setting other payer"])},
    "payerHasOverdueDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't set a payer who has overdue debt on his account"])},
    "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Payment Method"])},
    "paymentOptionsButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Options (Payment on File Required)"])},
    "addBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Bank Account"])},
    "addCreditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Credit Card"])},
    "addInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an invoice payment agreement"])},
    "couldNotGetSalesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting sales list"])},
    "couldNotGetInvoiceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting invoice details"])},
    "couldNotGetCreditNoteDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting credit notes details"])},
    "creditNoteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit note not found"])},
    "paymentOptionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment options"])},
    "dueNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due now"])},
    "hideOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide options"])},
    "chooseOtherOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or choose other options"])},
    "paymentAgreementNotFoundForChangeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment agreement not found for change subscription"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total VAT"])},
    "buyAddons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Addons"])}
  },
  "pps": {
    "errorUpdatingWebhookCache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating webhook cache"])},
    "errorGettingWebhookNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting webhook notification"])},
    "errorSettingWebhookNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while setting webhook notification"])}
  },
  "adyen": {
    "errorSavingAdyenSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while saving Adyen session"])},
    "errorGettingAdyenSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting Adyen session"])},
    "errorAdyenSessionNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adyen session not found"])},
    "errorAdyenSessionIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adyen session incorrect"])},
    "incorrectCardToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect card token"])}
  },
  "tabby": {
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order"])}
  },
  "person": {
    "emptyValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some values are empty"])},
    "errorZipCodeNull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code cannot be empty"])},
    "invalidSsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid social security number"])},
    "errorGettingAvailableSalutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get available salutations"])},
    "errorGettingAvailableLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get available languages"])},
    "errorGettingPersonDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get person detail"])},
    "errorGettingChildPersonDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get child details"])},
    "errorUpdatingChildDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update child details"])},
    "errorEmptyPersonDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No personal detail found"])},
    "errorUpdateInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update personal information"])},
    "errorUpdateCommunicationDetailsInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update communication details information"])},
    "errorInvalidSocialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid social security number"])},
    "errorWhileUpdatingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating status"])},
    "errorInvalidZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid zipcode"])},
    "errorInvalidPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password is invalid"])},
    "errorIllegalEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
    "updateEmailFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update email address"])},
    "emailAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address already exists"])},
    "updatePasswordFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update password"])},
    "noAvailableSalutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available salutations"])},
    "cannotValidatePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot validate person"])},
    "cannotValidatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update password"])},
    "invalidPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid person information"])},
    "cannotCreatePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot create person"])},
    "errorDuplicatePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person already exists"])},
    "errorIllegalArgument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illegal argument"])},
    "errorInvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "errorClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client error"])},
    "errorCreateException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception while creating the account"])},
    "errorWhileLoadingCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading cities"])},
    "errorMinimumAgeException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your birthday is invalid"])},
    "errorInvalidSSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid social security number"])},
    "errorDuplicateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate field"])},
    "unknownProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown duplicate property"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "company": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "numberOfEmployees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees"])},
    "contactPersonMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person mail"])},
    "contactPersonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person name"])},
    "noCompanyIsDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No company is defined"])},
    "companyPaymentAgreementsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company payment agreement"])},
    "companyCardsOnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company cards on file"])}
  },
  "subscription": {
    "buyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy New Subscription"])},
    "buyNewFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy New Subscription for"])},
    "personalInfoSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "addressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "contractSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "loadingCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading centers"])},
    "yourGym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Gym"])},
    "loadingSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Subscriptions"])},
    "yourPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Plan"])},
    "loadingSubscriptionInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Subscription Infos"])},
    "yourDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Details"])},
    "enterDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Details"])},
    "detailsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the details for"])},
    "selectNewPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new plan"])},
    "updateDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your Details"])},
    "selectYourPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your Plan"])},
    "membershipSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership summary"])},
    "currentSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current subscription"])},
    "selectedSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Subscription"])},
    "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership"])},
    "addOns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-ons"])},
    "clipCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard"])},
    "clipCardSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "memberDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member details"])},
    "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check email"])},
    "checkingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking email"])},
    "alreadyUserPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a user?"])},
    "alreadyUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email already exists. Please log in to continue or press cancel and update your information."])},
    "userAlreadyHasSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have the same subscription."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "checkCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Code"])},
    "checkingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking code.."])},
    "sendingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending code.."])},
    "resendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Code"])},
    "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you a code"])},
    "codeSentTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you a code to"])},
    "loginLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in.."])},
    "enterBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter it below."])},
    "enterYourDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Details"])},
    "enterPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Payment Details"])},
    "selectYourCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your Center"])},
    "newPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your New Plan"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "joiningFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joining fee"])},
    "selectMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Membership"])},
    "selectAddon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your add-ons"])},
    "promoCodeQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a promo code?"])},
    "promoCodeQuestionWithApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a promo code?"])},
    "errorGettingSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the subscriptions"])},
    "errorGettingPersonSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the member's subscriptions"])},
    "errorGettingEFTChangeSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the EFT change subscriptions"])},
    "getFreezesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the freezes"])},
    "errorGettingPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the payment agreement"])},
    "noDefaultActivePaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment agreement"])},
    "pendingValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending validation"])},
    "EFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EFT"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoice"])},
    "pendingEFTValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending EFT validation"])},
    "otherPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "invalidPromoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid promo code"])},
    "errorGettingPromoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the promo code"])},
    "invalidPlanId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid subscription"])},
    "planDoesNotBelongToMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan does not belong to member"])},
    "errorShoppingBasketInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the shopping basket infos"])},
    "cannotBuySubscriptionOutsideHomeCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot buy subscription outside home center"])},
    "noTerminationDateFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No termination date found. Please regret your existing termination first"])},
    "errorLoadingSuggestedTerminationDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading termination dates"])},
    "errorTerminatingSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error terminating subscription"])},
    "errorRegretCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while regreting cancellation"])},
    "noSubscriptionAvailableForSelectedCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Subscription available for the selected center"])},
    "newAddon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Addon"])},
    "errorGettingAddons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get addons for product"])},
    "loadingAddons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Addons"])},
    "noAddonsAvailableForSelectedCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addons available for the subscription"])},
    "emailField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "confirmEmailField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm email"])},
    "subscriptionStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription start date"])},
    "subscriptionBuyNewStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription start date"])},
    "subscriptionChangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription change date"])},
    "enterStartupDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a startup date"])},
    "startupBeforeToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup date cannot be before today"])},
    "startupBeforeClubDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startup date cannot be before the club's startup date: ", _interpolate(_named("startupDate"))])},
    "startupBeforeSubscriptionDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startup date cannot be before your current subscription's end date. First possible start date is ", _interpolate(_named("startupDate"))])},
    "startDateBeforeSubscriptionEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date cannot be before your current subscription's end date"])},
    "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password"])},
    "passwordStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password strength"])},
    "tooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too weak"])},
    "weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weak"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
    "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your password"])},
    "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too weak"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an email"])},
    "invalidEmailCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email code"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your email"])},
    "emailMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation email does not match"])},
    "sendVerifyEmailFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot send the verification email"])},
    "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
    "passwordMissmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "enterGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your gender"])},
    "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred language"])},
    "enterPreferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your preferred language"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "enterFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your first name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "enterLastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your last name"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "enterBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your birthday"])},
    "belowMinimumAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member is below the minimum age"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "enterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your phone number"])},
    "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number"])},
    "idType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Type"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "nationalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Id"])},
    "residentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resident Id"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "issuanceCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuance Country"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
    "selectIdType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select ID type"])},
    "selectIdNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
    "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your address"])},
    "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Security Number"])},
    "invalidCpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid cpr number"])},
    "enterSSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your social security number"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "enterState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your state"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
    "enterZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your zip code"])},
    "zipCodePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000-000"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "enterCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your city"])},
    "loadingCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading cities.."])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "enterCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your country"])},
    "enterSalutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your salutation"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "iAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
    "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept the terms and conditions"])},
    "acceptCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept the condition"])},
    "gdprCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept the GDPR condition"])},
    "allowThirdPartyOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept third party offers"])},
    "allowEmailNewsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept to receive email newsletters"])},
    "selectPassportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your passport's expiration date"])},
    "passportDateInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport's expiration date is invalid"])},
    "selectPassportCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your passport's issuance country"])},
    "invalidField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid field: ", _interpolate(_named("fieldName"))])},
    "checkFormAndTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the form and try again."])},
    "recurringPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring payment"])},
    "fixedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed payment"])},
    "enterRoutingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the routing number"])},
    "enterAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the account number"])},
    "enterBankAccountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the bank account type"])},
    "invalidStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid start date"])},
    "errorLoadingContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading contract"])},
    "invalidProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid product"])},
    "selectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a payment method"])},
    "errorMoreThanOneDefaultPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is more than one default payment agreement"])},
    "errorCreatePaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating payment agreement"])},
    "invalidPaymentAgreementReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid payment agreement reference"])},
    "paymentAgreementReferenceNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment agreement reference not found"])},
    "errorUpdateCCPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating Credit Card payment agreement"])},
    "errorUpdateACHPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating Direct Debit payment agreement"])},
    "errorCreateCCPaymentAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating credit card payment agreement"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription not found"])},
    "errorLoadingInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while loading invoice"])},
    "errorGettingProductsForBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting products for booking"])},
    "subscriptionHasEndDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subscription already has end date ", _interpolate(_named("date")), ". Subscription state is ", _interpolate(_named("state"))])},
    "bookingInFreezePeriod": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are participations during the freeze period that could not be maintained. ", _interpolate(_named("others"))])},
    "freezeNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze is not allowed"])},
    "freezeUnitAmountUndefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze is not allowed"])},
    "freezeOverlap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The provided date range overlaps an existing subscription freeze period. ", _interpolate(_named("others"))])},
    "freezeStartsAfterSubscriptionEnds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided freeze start date is after the subscription end date"])},
    "freezeStartBeforeSubscriptionStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided freeze start date is before the subscription start date"])},
    "freezeEndBeforeSubscriptionEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided freeze is outside subscription range"])},
    "freezeTooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze is longer than the allowed period"])},
    "freezeTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze is shorter than the allowed period"])},
    "tooManyFreezesPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many freeze periods in subscription"])},
    "tooManyFreezesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many freeze total in subscription"])},
    "freeze": {
      "errorFreezeCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while cancelling freeze"])},
      "errorFreezeStopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while stopping freeze"])},
      "errorGettingFreezeProductForCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting freeze product for currency"])},
      "errorGettingFreezeDetailsForSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting freeze details for subscription"])},
      "errorGettingAvailableFreeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting available freeze"])},
      "errorGettingSubscriptionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting subscription details"])},
      "errorGettingFreezeStartProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting freeze information"])},
      "errorGettingFreezeInvoicePreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting freeze invoice preview"])},
      "errorCreatingFreeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating freeze"])},
      "freezeSubscriptionNotUserSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot freeze a subscription that is not yours"])},
      "freezeSubscriptionFeeGreaterThanZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot freeze a subscription with a fee greater than zero"])},
      "prepareShoppingBasketForFreezeSubscriptionFeeEqualsZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze fee is not correct"])},
      "configurationChangedForceReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration changed. Please reload your page"])}
    },
    "offer": {
      "retentionConfigurationIsIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention configuration is incomplete"])},
      "errorUpdatingExtendedAttributeForOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while updating extended attribute for offer"])}
    },
    "banner": {
      "membershipLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership"])},
      "addOnsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-ons"])},
      "clipCardLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clipcard"])},
      "discountedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted price"])},
      "plusAddons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ addons"])}
    }
  },
  "resetPassword": {
    "findPersonByEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to change your password please try again later"])},
    "sendPasswordToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to change your password please try again later"])},
    "changePasswordWithToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to change your password please try again later"])},
    "enterYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a member you will receive an email"])},
    "passwordsMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords match"])},
    "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been successfully updated"])},
    "continueToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to login page"])},
    "noMailFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No email found"])}
  },
  "invoice": {
    "status": {
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "partialPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially paid"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
    },
    "type": {
      "DEBT_COLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEBT_COLLECTION"])},
      "LEGACY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGACY"])},
      "PAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYMENT"])},
      "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
      "REMINDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMINDER"])},
      "REPRESENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPRESENTATION"])},
      "REVERSAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSAL"])},
      "ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZERO"])}
    },
    "yourFinancials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Financials"])},
    "yourInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your invoices"])},
    "debtFullyPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully paid your debt"])},
    "debtPartiallyPaid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have paid ", _interpolate(_named("amount")), " of your debt"])},
    "yourPrimaveraInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your certified invoices"])},
    "salesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your purchase history"])},
    "cashAccountBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash account balance"])},
    "payableDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payable debt"])},
    "payDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAY DEBT"])},
    "payDebtWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PAY DEBT WITH ", _interpolate(_named("provider"))])},
    "topUpAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP UP ACCOUNT"])},
    "topUpAccountWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["TOP UP ACCOUNT WITH ", _interpolate(_named("provider"))])},
    "topUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP UP"])},
    "settlePaymentRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settle payment requests"])},
    "totalSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total selected"])},
    "whichToSettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick which payment requests to settle"])},
    "invoiceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "openAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open amount"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "dateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "reasonType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "transactionTypeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])},
    "transactionType": {
      "CUSTOM_PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom payment method"])},
      "CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CASH"])},
      "CREDIT_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])},
      "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
      "PAYOUT_CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout cash"])},
      "CASH_AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash AR"])},
      "DEBIT_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit card"])},
      "CARD_UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown card"])},
      "GIFT_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift card"])},
      "CASH_ADJUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash adjust"])},
      "TRANSFER_BANK_CASH_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank cash notes transfer"])},
      "PAYMENT_AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ar"])},
      "CASH_REGISTER_PAYOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash register payout"])},
      "CREDIT_CARD_ADJUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card adjust"])},
      "CLOSING_CASH_ADJUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing cash adjust"])},
      "VOUCHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
      "PAYOUT_CREDIT_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout credit card"])},
      "TRANSFER_CASH_REGISTER_CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer cash register cash"])},
      "CLOSING_CREDIT_CARD_ADJUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing credit card adjust"])},
      "TRANSFER_BANK_CASH_COINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank cash coins transfer"])},
      "INSTALLMENT_PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment plan"])},
      "INITIAL_CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial cash"])},
      "MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
      "METHOD_NOT_DEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method not defined"])}
    },
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "noTransactionFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions found"])},
    "statusColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOAD"])},
    "searchFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "loadingInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading invoices"])},
    "noInvoicesForDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoices available for the selected date range"])},
    "loadingSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading sales"])},
    "noSalesForDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sales available for the selected date range"])},
    "noInvoiceDetailFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice detail found"])},
    "loadingInvoiceDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading invoice detail"])},
    "invoiceDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice details"])},
    "creditNoteDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit note details"])},
    "topUpPopupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay money into your cash account"])},
    "topUpPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up"])},
    "topUpPopupAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up amount"])},
    "topUpPopupAmountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount before continuing"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "primavera": {
      "couldNotGetInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get invoices"])},
      "errorConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while configuring Primavera"])},
      "primaveraConfigurationIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primavera configuration incomplete"])},
      "errorGettingPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting person info"])},
      "personDetailsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person details not found"])},
      "errorGettingInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get invoices"])},
      "errorDownloadingInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error downloading invoices"])}
    }
  },
  "staff": {
    "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected as"])},
    "selectedMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected member"])},
    "loadingUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading users .."])},
    "noMembersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No members found"])},
    "buyClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Clipcard"])},
    "searchMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Members"])},
    "activityGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Groups"])},
    "allGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Groups"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
    "classBookingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Booking Schedule"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "externalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Id"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "extId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ext. Id"])},
    "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
    "memberClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Clipcards"])},
    "memberSubscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Subscriptions"])},
    "buyClipcardSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Clipcard Subscription"])},
    "memberSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member schedule"])}
  },
  "familyMembers": {
    "actingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are acting for "])}
  },
  "questionnaire": {
    "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "getActiveQuestionnaireError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting the list of questionnaires"])},
    "getQuestionnaireTemplateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting the questionnaire template"])},
    "loadingTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading templates..."])},
    "submitAnswerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error submitting the answer"])},
    "getAnswerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting previous answers"])},
    "errorGettingCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting questionnaire campaigns"])}
  },
  "resourceBooking": {
    "bookingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking type"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durations"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "availableStartTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available start times"])},
    "filterMorningStartTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "filterAfternoonStartTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "noResourcesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resources found for the selected filters"])},
    "checkPrivilegeForActivityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error checking the privilege for the selected activity"])},
    "noCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No centers found for the selected filters"])},
    "getCourtActivitiesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading booking types"])},
    "getAvailableStartTimesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading available start times"])},
    "errorCheckResourceAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error checking resource availability"])},
    "getGetCourtResourcesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading resources"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "availableResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available resources"])},
    "bookResourceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error booking resource"])},
    "resourceOccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource is already occupied"])},
    "courtActivityDurationEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Court durations can't be empty"])},
    "resourceNotOfTypeCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource is not of type court"])},
    "bookingSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "bookingSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource booked successfully"])},
    "bookingSuccessClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "bookingConfirmationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your booking"])},
    "bookingConfirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to book ", _interpolate(_named("name")), " on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "?"])},
    "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "abortBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
    "noSessionsOnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not currently have any sessions on your account. Would you like to purchase a session?"])},
    "buyAProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy a product"])},
    "loadingScheduleDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading the schedule dates"])},
    "selectFamilyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select family member"])},
    "activityGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity group"])},
    "getActivityGroupsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting the activity groups"])}
  },
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! The page you're looking for isn't here."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might have the wrong address, or the page may have moved."])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "errorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])}
  },
  "course": {
    "searchCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Courses"])},
    "errorGettingCourseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load the course filters"])},
    "errorGettingCourseSemesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load the course filters"])},
    "errorSearchingCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot search courses"])},
    "errorGettingAgeGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the age groups"])},
    "errorDeletingItemFromShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete the item from the shopping basket. Please refresh the page and try again."])},
    "errorSavingCachedShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save the shopping basket"])},
    "errorGettingCourseDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the details"])},
    "notClipcardProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a Clipcard product"])},
    "errorUpdatingCachedShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update the shopping basket"])},
    "errorRetrievingCachedShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot retrieve the cached shopping basket"])},
    "tooLateToSignupToCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too late to sign up to course"])},
    "cannotBookCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot book course"])},
    "selectFamilyMemberForCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a family member to purchase the course"])},
    "noMoreClassesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no more classes available to book."])},
    "remainingCapacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Capacity"])},
    "ageRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age restriction"])},
    "fromAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From age"])},
    "clips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["one clip"]), _normalize([_interpolate(_named("count")), " clips"])])},
    "shoppingBasketHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses Shopping Basket"])},
    "shoppingBasketTotalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "shoppingBasketAmountToPayToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay today"])},
    "shoppingBasketVatAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TAX"])},
    "shoppingBasketIncludingTaxAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including tax amount"])},
    "shoppingBasketPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Methods"])},
    "shoppingBasketReadAndAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
    "shoppingBasketReadAndAcceptTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
    "cannotAddClipcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add course to the shopping basket"])},
    "installmentsNotEnabledForMultipleCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shopping basket contains multiple course bookings. To see our installment plan offerings, try adding only one at a time."])},
    "errorMessageWhenEnableFamilyRelationsIsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course is not available for you. Please see the course details for any restrictions."])},
    "addToStandbyListText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The slots are complete, would you like to add yourself in standby list ?"])},
    "standbyListFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standby list full"])},
    "addToStandbyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to standby list"])},
    "cannotAddCourseToStandbyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding To standby List failed"])},
    "sameProgramExistsInStandbyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course already exists in standby list for the member"])},
    "standbyListAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course added to standby list for the selected member"])},
    "standbyListAddSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success adding course to standby list"])},
    "standbyListIsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standby list is disabled"])}
  },
  "camps": {
    "searchCamps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Camps"])},
    "errorBookingCamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot book the camp"])},
    "cannotDeleteBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete the booking"])},
    "errorGettingCampTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load the camp filters"])},
    "errorGettingCampSemesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load the camp filters"])},
    "errorSearchingCamps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot search camps"])},
    "errorGettingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the camps details"])},
    "errorRetrievingCachedCampsShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot retrieve the cached camps shopping basket"])},
    "errorCreatingCachedCampsShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot create the camps shopping basket"])},
    "errorSavingCachedCampsShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save the camps shopping basket"])},
    "errorGettingSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while getting the camps summary"])},
    "cannotAddCampToBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot add the camp to the basket"])},
    "programNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program not found"])},
    "selectFamilyMemberForCamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a family member to purchase the camp"])},
    "noClassAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Class available"])},
    "selectAdditionalProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select additional products (optional)"])},
    "additionalProductsAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional product(s) selected with total amount"])},
    "alreadyAddedToShoppingBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shopping basket already contains a booking for the selected member."])},
    "errorMessageWhenEnableFamilyRelationsIsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This camp is not available for you. Please see the camp details for any restrictions."])}
  },
  "bringAFriend": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With your current membership you can bring friends to the gym to train together with you. Completely free!"])},
    "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just share your personal \"Bring A Friend\" link with them and once your friend has signed up you will be able to access the gym together."])},
    "sessionsLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining sessions"])},
    "totalSessionsLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Remaining sessions"])},
    "noClipcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current membership does not allow you to bring a friend."])},
    "yourLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal link"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My link"])},
    "shareMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi! Here is the link my friend:"])},
    "invalidFriendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid friend code"])},
    "noClipsLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The member who invited you has reached their limit of invitation sessions."])},
    "cannotReferYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot refer yourself"])},
    "noClipcardAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session not available"])},
    "noPaymentProviderAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment provider available"])},
    "friendDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your friend has invited you to train together with them."])},
    "friendInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please click on the button bellow to receive your free session. You will then be able to use it to train together with your friend."])},
    "useYourFriendsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your friend's Code"])},
    "clipcardMaxPurchaseExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of free sessions. It might be time for you to consider joining the gym?"])},
    "validUntilDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
  },
  "gdpr": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already accepted the GDPR above."])}
  },
  "lead": {
    "memberDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member details"])},
    "mainHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Lead Form"])},
    "fillInfoHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your information"])},
    "errorCreatingLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating lead!"])},
    "noPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead creation is not enabled"])},
    "successHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now created as a lead"])},
    "enterOtpCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code"])}
  }
}